import React from "react"
import { graphql } from 'gatsby'
import Img from "gatsby-image/withIEPolyfill"
import { Link } from "../components/link"
import Layout from "../components/layout"
import SEO from "../components/seo"


const TemplateNews = ({ pageContext: { breadcrumbs }, data }) => {
  const { pagina } = data;
  const { immagine, titolo, testo } = pagina;

  return (
    <Layout data={data}>
      <SEO page={pagina} data={data} />

      <div id="main" role="main">
        {/* Hero */}
        {immagine && (
          <div className="hero">
            <Img fluid={immagine.fluid} alt={titolo} objectFit="contain" objectPosition="center center" />
          </div>
        )}

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={`${immagine ? 'prodotto has-hero' : 'prodotto'}`}>
                <div className="row">
                  <div className="col-12">
                    {/* Breadcrumb */}
                    <div id="breadcrumbs" className="breadcrumbs clearing" role="navigation" aria-label="breadcrumb">
                      <ol className="breadcrumbs__list" itemType="http://schema.org/BreadcrumbList" itemScope="itemscope">
                        <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement">
                          <div aria-level="1" itemProp="item">
                            <Link to="/" title="Homepage" className="breadcrumbs__link" itemProp="url"><span itemProp="name">Homepage</span></Link>
                          </div>
                          <meta content="1" itemProp="position" />
                        </li>
                        {breadcrumbs.map((item, i) => (
                          <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement" key={i}>
                            <div aria-level={i + 2} itemProp="item">
                              <Link to={item.link} title={item.name} className="breadcrumbs__link" itemProp="url"><span itemProp="name">{item.name}</span></Link>
                            </div>
                            <meta content={i + 2} itemProp="position" />
                          </li>
                        ))}
                        <li className="breadcrumbs__item" itemType="http://schema.org/ListItem" itemScope="itemscope" itemProp="itemListElement">
                          <div aria-level={breadcrumbs.length + 2} itemProp="item">
                            <span itemProp="name">{titolo}</span>
                          </div>
                          <meta content={breadcrumbs.length + 2} itemProp="position" />
                        </li>
                      </ol>
                    </div>

                    {/* Titolo  */}
                    <h1 className="prodotto__title">{titolo}</h1>

                    {/* Testo */}
                    {testo && <div className="prodotto__text" dangerouslySetInnerHTML={{ __html: testo.childMarkdownRemark.html }}></div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default TemplateNews;

export const query = graphql`
query NewsDettaglioQuery($contentful_id: String!){
  ...DefaultSeo
  ...HeaderFragment
  ...FooterRootFragment
  pagina: contentfulComunicazioniENews(contentful_id: {eq: $contentful_id}) {
    titolo
    seo {
      title
      friendlyUrl
      description
      robots
      ogtitle
      ogdescription
      ogimage{
        fixed(width: 1920, quality: 100){
          src
        }
      }
    }
    testo {
      childMarkdownRemark {
        html
      }
    }
    immagine {
      fluid(maxWidth:1920) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    data(formatString: "DD MMMM YYYY", locale: "it_IT")
  }
}`;